import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import './index.css';
import App from './App';
import configureStore from './store';
import { restoreCSRF, csrfFetch } from './store/csrf';
import * as sessionActions from './store/session';

import { ModalProvider } from './context/ModalContext';
import { WindowProvider } from './context/WindowContext';

const store = configureStore();



if (process.env.NODE_ENV !== 'production') {
  restoreCSRF();
  window.csrfFetch = csrfFetch;
  window.store = store;
  window.sessionActions = sessionActions;
}



function Root() {
  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <WindowProvider> 
          <ModalProvider> 
            <App />
          </ModalProvider>
        </WindowProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);


